import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));

// render - sample page
const AboutUs = Loadable(lazy(() => import('pages/extra-pages/about-us')));
const RoadMap = Loadable(lazy(() => import('pages/extra-pages/roadmap')));

//BeaconLight Pages
const WalletAnalysis = Loadable(lazy(() => import('pages/app/wallet-analysis')));
const NewProjects = Loadable(lazy(() => import('pages/app/new-projects')));
const FeaturedProjects = Loadable(lazy(() => import('pages/app/featured-projects')));
const TrendingProjects = Loadable(lazy(() => import('pages/app/trending-projects')));
const SingleCollection = Loadable(lazy(() => import('pages/app/single-collection')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        //<AuthGuard>
          <MainLayout />
        //</AuthGuard>
      ),
      children: [
        {
          path: 'nft',
          children: [
            {
              path: ":contractAddress",
              element: <SingleCollection />
            }
          ]
        },
        {
          path: 'featured-projects',
          element: <FeaturedProjects />
        },
        {
          path: 'trending-projects',
          element: <TrendingProjects />
        },
        {
          path: 'new-projects',
          element: <NewProjects />
        },
        {
          path: 'wallet-analysis',
          element: <WalletAnalysis />
        },
        {
          path: 'about',
          element: <AboutUs />
        },
        {
          path: 'roadmap',
          element: <RoadMap />
        },
        
      ]
    },
    {
      path: '*',
      element: <MaintenanceError />
    }
  ]
};

export default MainRoutes;
