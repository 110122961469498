import { useRoutes } from 'react-router-dom';

// project import
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

import CommonLayout from 'layout/CommonLayout';

import Loadable from 'components/Loadable';

import { lazy } from 'react';

const HomePage = Loadable(lazy(() => import('pages/extra-pages/homepage')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          element: <HomePage />
        }
      ]
    },
    LoginRoutes,
    MainRoutes
  ]);
}

