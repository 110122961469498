import * as React from 'react';
import lodash from 'lodash';

// material-ui
import { Box, FormControl, InputAdornment, OutlinedInput, TextField } from '@mui/material';

// assets
import { SearchOutlined } from '@ant-design/icons';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import Moralis from 'moralis';

import { useNavigate } from "react-router-dom";

// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const DEBOUNCE_DELAY = 500;

const Search = () => {
  const [open, setOpen] = React.useState(false);
  const [results, setResults] = React.useState(null);
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);
  const navigate = useNavigate();

  const callAPI = (inputValue2) => {
    console.count("api");
    const params = { contractName: inputValue2 };
    Moralis.Cloud.run("searchCollection", params).then(
      (results) => {
        setResults(results);
      }
    );
  }

  const deBounceCall = React.useCallback(lodash.debounce(callAPI,DEBOUNCE_DELAY),[])
  console.count("Render Search Component");
  React.useEffect(() => {
    let active = true;
    if (inputValue === '') {
      setOptions([]);
      return undefined;
    } else {
      deBounceCall(inputValue);
    }

    return () => {
      active = false;
    };

  }, [inputValue]);

  React.useEffect(() => {
    if (results) {
      setOptions(results);
    }
  }, [results]);

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      <Autocomplete
        filterOptions={(x) => x}
        id="asynchronous-demo"
        sx={{ width: 300 }}
        value={null}
        onChange={(event, newValue) => {
          navigate('/nft/'+newValue.get('tokenAddress'));
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        
        getOptionLabel={(option) => option.get('contractName')}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 }, color:'white' }} {...props}>
            <img
              loading="lazy"
              width="20"
              src={option.get('collectionImg')}
              srcSet={option.get('collectionImg')}
              alt=""
            />
            {option.get('contractName')}
          </Box>
        )}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{ width: { xs: '100%', md: 280 } }}
            placeholder="Search collections"
            aria-describedby="header-search-collection"
            InputProps={{
              ...params.InputProps,
              size: "small",
              startAdornment: (
                <InputAdornment position="start" sx={{ mr: -0.5 }}>
                  <SearchOutlined />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  {loaded.current ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    </Box>

  );
}

export default Search;
