// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BarChartOutlined,
  HeatMapOutlined
} from '@ant-design/icons';

// icons
const icons = {
  BarChartOutlined,
  HeatMapOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const walletMenu = {
  id: 'wallets',
  title: <FormattedMessage id="wallets" />,
  type: 'group',
  children: [
    {
      id: 'wallet-analysis',
      title: <FormattedMessage id="wallet-analysis" />,
      type: 'item',
      url: '/wallet-analysis',
      icon: icons.BarChartOutlined
    },
    {
      id: 'top-wallets',
      title: "Top Wallets",
      type: 'item',
      url: '/top-wallets',
      icon: icons.HeatMapOutlined,
      chip: {
        label: 'coming',
        color: 'secondary',
        size: 'small'
      }
    }
  ]
};

export default walletMenu;
