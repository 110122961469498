// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  LikeOutlined,
  RadarChartOutlined,
  RiseOutlined
} from '@ant-design/icons';

// icons
const icons = {
  LikeOutlined,
  RadarChartOutlined,
  RiseOutlined
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const nftMenu = {
  id: 'NFTs',
  title: <FormattedMessage id="NFTs" />,
  type: 'group',
  children: [
    {
      id: 'featured-projects',
      title: 'Featured NFTs',
      type: 'item',
      url: '/featured-projects',
      icon: icons.LikeOutlined
    },
    {
      id: 'trending-projects',
      title: 'Trending NFTs',
      type: 'item',
      url: '/trending-projects',
      icon: icons.RiseOutlined
    },
    {
      id: 'new-projects',
      title: 'New NFTs',
      type: 'item',
      url: '/new-projects',
      icon: icons.RadarChartOutlined
    }
  ]
};

export default nftMenu;
