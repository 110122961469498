// project import
import other from './other';
import nftMenu from './nft-menu';
import walletMenu from './wallet-menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [nftMenu, walletMenu, other]
};

export default menuItems;
